<template>
  <div class="d-flex">
    {{ params.cleaned_start_date }} - {{ params.cleaned_end_date }}
  </div>

</template>

<script>
export default {
  name: 'CellRendererEventOn',
  props: {
    params: Object,
  },

}
</script>
