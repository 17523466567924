<template>
  <div class="flex">
    <b-avatar
      :src="params.avatar"
      class="flex-shrink-0 mr-2"
      size="30px"
      @click="$router.push(url)"
    />
    <span>{{ params }}</span>
  </div>

</template>

<script>
import {
  BAvatar,
} from 'bootstrap-vue'

export default {
  name: 'CellRendererName',
  components: {
    BAvatar,
  },
  props: {
    params: Object,
  },
  computed: {
    chipColor() {
      return value => {
        if (value === 'active') return 'success'
        if (value === 'blocked') return 'danger'
        if (value === 'deactivated') return 'warning'
        return 'primary'
      }
    },
  },
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.b-badge pill-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.b-badge pill-warning {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1) !important;
    font-weight: 500;
  }
  &.b-badge pill-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}
</style>
