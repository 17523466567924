<template>
  <div class="d-flex justify-content-center">
    <div class="mx-auto">

      <b-button
        v-if="params.total_applications > 0"
        v-b-tooltip.hover.top="'Total number of applicants. Click to view more.'"
        :to="'/events-manager/applications/' + params.event_id + (default_name === 'Job' ? '_j' : '')"
        :variant="params.total_applications > 0 ? 'success' : 'primary'"
      >{{ params.total_applications }}</b-button>

      <b-button
        v-else
        variant="primary"
      >0</b-button>
    </div>
  </div>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'CellRendererApplicants',
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    params: Object,
  },
  data() {
    return {
      default_name: 'Event',
    }
  },
  computed: {},
  created() {
    const join_event_id = this.$route.params.event_id

    if (join_event_id || this.params.table === 'events_jobs') {
      this.default_name = 'Job'
    }
  },
}
</script>
